import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import {
  Box,
  Flex,
  Heading,
  Image,
  keyframes,
  Link,
  Text,
  useBreakpointValue,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import bloctoLogo from '../../static/images/icon-blocto-logo.svg';
import appStore from '../../static/images/img-app-store.png';
import playStore from '../../static/images/img-play-store.png';
import phoneOutline from '../../static/images/img-phone-outline.png';
import bloctoDemoVideo from '../../static/videos/video-blocto-demo.mp4';

const BackupLinks = {
  'iOS TestFlight': 'https://testflight.apple.com/join/zxlMasyx',
  'Android APK': 'https://cdn.blocto.app/blocto-latest.apk',
};

const expand = keyframes`
  from { opacity: 0.7; transform: scale(1); }
  to { opacity: 0; transform: scale(1.5); }
`;

const opacity = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const aosAttributes = {
  'data-aos': 'fade-up',
  'data-aos-duration': '600',
};

const AppDemo = ({ ...rest }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  const expandAnimation = prefersReducedMotion
    ? undefined
    : `${expand} 1 .6s linear forwards`;

  const handleAnimationEnd = () => {
    setIsAnimated(true);
  };

  const handleLoadedData = () => {
    setIsLoaded(true);
  };

  return (
    <Box
      flex="0 0 309px"
      maxWidth="309px"
      marginTop={{ base: '40px', md: '0' }}
      position="relative"
      {...rest}
    >
      <Image src={phoneOutline} width="100%" />

      <Flex
        justifyContent="center"
        alignItems="center"
        width="calc(100% - 30px)"
        minWidth="220px"
        height="calc(100% - 2%)"
        maxHeight="609px"
        borderRadius="30px"
        position="absolute"
        top="11px"
        left="50%"
        zIndex="-1"
        transform="translateX(-50%)"
        bg="white"
        opacity={!isLoaded || !isAnimated ? 1 : 0}
        boxShadow="0px 20px 30px rgba(0, 0, 0, 0.1)"
        transition=".5s opacity"
      >
        <Image
          src={bloctoLogo}
          width="107px"
          height="107px"
          animation={expandAnimation}
          onAnimationEnd={handleAnimationEnd}
        />
      </Flex>

      <Box
        as="video"
        playsInline
        autoPlay
        muted
        loop
        width="calc(100% - 30px)"
        borderRadius="30px"
        position="absolute"
        top="11px"
        left="50%"
        zIndex="-2"
        transform="translateX(-50%)"
        boxShadow="0px 20px 30px rgba(0, 0, 0, 0.1)"
        sx={{
          '@media screen and (max-width: 309px)': {
            width: 'calc(100% - 24.5px)',
          },
        }}
        onLoadedData={handleLoadedData}
      >
        <source src={bloctoDemoVideo} type="video/mp4" />
      </Box>
    </Box>
  );
};

const Download = ({ intl }) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const isMinWidthLg = useBreakpointValue({ base: false, md: true });

  if (isMinWidthLg === undefined) {
    return (
      <SEO
        title={intl.formatMessage({ id: 'blocto.download.meta.title' })}
        description={intl.formatMessage({
          id: 'blocto.download.meta.description',
        })}
        path="download"
      />
    );
  }

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'blocto.download.meta.title' })}
        description={intl.formatMessage({
          id: 'blocto.download.meta.description',
        })}
        path="download"
      />

      <Flex
        justifyContent={{ base: 'center', md: 'flex-start' }}
        margin={{ base: '41px 0 200px', md: '60px 0 200px' }}
        position="relative"
      >
        <Flex
          flexDirection="column"
          alignItems={{ base: 'center', md: 'unset' }}
          maxWidth="580px"
          paddingTop={{ base: '0', md: '54px' }}
          marginRight={{ base: '0', md: 'min(16.5%, 198px)' }}
          textAlign={{ base: 'center', md: 'left' }}
          sx={{
            '@media screen and (min-width: 767px) and (max-width: 960px)': {
              marginRight: '20px',
            },
          }}
          {...(isMinWidthLg ? aosAttributes : {})}
        >
          <Box {...(isMinWidthLg ? {} : aosAttributes)}>
            <Image
              src={bloctoLogo}
              alt="Blocto logo"
              display="inline-block"
              width={{ base: '100px', md: '120px' }}
              height={{ base: '100px', md: '120px' }}
              padding={{ base: '13px', md: '23px' }}
              borderRadius="24px"
              boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
            />
            <Heading
              as="h1"
              variant="h1"
              margin="30px auto"
              maxWidth={{ base: '304px', md: 'unset' }}
            >
              <FormattedMessage id="blocto.download.title" />
            </Heading>

            <Text fontSize="20px" lineHeight="26px" whiteSpace="break-spaces">
              <FormattedMessage id="blocto.download.content" />
            </Text>
          </Box>

          <AppDemo
            display={{ base: 'unset', md: 'none' }}
            margin="60px 0"
            {...(isMinWidthLg
              ? {}
              : { ...aosAttributes, 'data-aos-delay': '1400' })}
          />

          <Box
            display={{ base: 'block', md: 'flex' }}
            justifyContent="space-between"
            maxWidth="384px"
            margin={{ base: '0 0 30px', md: '70px 0 30px' }}
            {...(isMinWidthLg ? {} : aosAttributes)}
          >
            <Link
              href="https://apps.apple.com/app/blocto/id1481181682"
              isExternal
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="180px"
                height="60px"
                padding="12px"
                bg="porttoGrayBlue"
                borderRadius="12px"
                marginRight={{ base: '0', md: '24px' }}
                marginBottom={{ base: '20px', md: '0' }}
              >
                <Image src={appStore} width="100%" />
              </Flex>
            </Link>

            <Link
              href="https://play.google.com/store/apps/details?id=com.portto.blocto"
              isExternal
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                width="180px"
                height="60px"
                padding="12px"
                bg="porttoGrayBlue"
                borderRadius="12px"
              >
                <Image src={playStore} width="100%" />
              </Flex>
            </Link>
          </Box>

          <Text
            fontSize="16px"
            lineHeight="22px"
            color="porttoGray01"
            sx={{
              ' a': {
                display: 'inline-block',
                fontWeight: '500',
                textDecoration: 'underline',
              },
              ' a:hover': {
                textDecoration: 'underline',
              },
            }}
            {...(isMinWidthLg ? {} : aosAttributes)}
          >
            <FormattedMessage
              id="blocto.download.backup"
              values={{
                a: (str) => (
                  <Link href={BackupLinks[str]} isExternal>
                    {str}
                  </Link>
                ),
              }}
            />
          </Text>
        </Flex>

        <AppDemo
          display={{ base: 'none', md: 'unset' }}
          sx={{
            '@media screen and (min-width: 767px) and (max-width: 960px)': {
              '&[data-aos][data-aos].aos-animate': {
                transform: 'scale(0.9)',
              },
            },
          }}
          {...(isMinWidthLg ? aosAttributes : {})}
        />
      </Flex>
    </Layout>
  );
};

export default injectIntl(Download);
